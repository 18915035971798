<template lang="pug">
v-container(
  fill-height,
  fluid,
  v-if="loaded"
)
  v-layout(
    align-center,
    justify-center
  )
    v-flex(
      shrink
    )
      v-card(
        :min-width="cardSize.w",
        v-if="term._id"
      )
        v-toolbar#trainer-toolbar(
          color="primary",
          dense,
          flat
        )
          v-toolbar-title # {{ index + 1 }}
          v-spacer
          v-toolbar-items
            v-btn(
              :disabled="!hint && !revealed",
              @click="reset",
              icon,
              ripple,
              title="Reset Word"
            )
              v-icon mdi-refresh
            v-btn(
              :disabled="!hasSpeech",
              @click="sayWord",
              icon,
              title="Pronounce Word"
            )
              v-icon mdi-volume-high
            v-menu(
              :close-on-content-click="false",
              left,
              nudge-right="12",
              offset-y,
              open-delay="100",
              v-model="menu"
            )
              template(
                v-slot:activator="{ on, attrs }"
              )
                v-btn#settings(
                  icon,
                  ripple,
                  title="Open Settings",
                  v-bind="attrs",
                  v-on="on"
                )
                  v-icon mdi-cog
              v-list(
                dense
              )
                v-list-item(
                  dense
                )
                  v-list-item-content.pt-1.pb-0
                    v-checkbox.pt-1.mt-0(
                      :disabled="true",
                      @change="toggleSort",
                      dense,
                      hide-details,
                      label="Sort List",
                      readonly,
                      v-model="sorted"
                    )
                v-divider.my-2(
                  v-if="sorted"
                )
                v-slide-y-reverse-transition(
                  mode="out-in"
                )
                  v-list-item(
                    dense,
                    v-if="sorted"
                  )
                    v-list-item-content(
                      @click.prevent="",
                      dense
                    )
                      v-text-field(
                        :hint="`1 - ${terms}`",
                        :max="terms",
                        :rules="jumpRules",
                        @keydown.enter="jumpToIndex",
                        dense,
                        label="Skip to #",
                        min="1",
                        step="1",
                        type="number",
                        v-model="jumpTo"
                      )
        v-container.pa-4.mt-4(
          :style="{ minHeight: cardSize.h + `px` }",
          fluid,
          v-touch="{ left: next, right: previous }"
        )
          v-layout(
            @click="() => (revealed ? next() : reveal())",
            fill-height,
            style="cursor: pointer",
            wrap
          )
            v-slide-x-transition(
              mode="out-in"
            )
              v-flex(
                grow,
                v-if="!trans"
              )
                p.overline.my-2 EN:
                p.display-1.text--primary {{ term.en }}
                p.overline.mb-2 DE:
                v-fade-transition(
                  mode="out-in"
                )
                  p.display-1.text--primary(
                    style="overflow-wrap: break-word",
                    v-if="revealed"
                  )
                    | {{ term.de }}
                  p.display-1.text--primary(
                    style="letter-spacing: 2px !important; overflow-wrap: break-word",
                    v-else-if="hint && !revealed"
                  )
                    | {{ wordHint }}
                v-flex(
                  fill-height
                )
        v-divider
        v-card-actions
          v-btn(
            :disabled="!index",
            @click="previous",
            icon,
            title="Previous Word"
          )
            v-icon mdi-chevron-left
          v-spacer
          v-btn(
            :disabled="hint || revealed",
            @click="showHint",
            color="primary",
            raised,
            ripple,
            small,
            style="transition: 0.3s ease-out",
            tile,
            title="Show Hint"
          ) Show Hint
          v-spacer
          v-btn(
            :disabled="index === ids.length - 1",
            @click="next",
            icon,
            title="Next Word"
          )
            v-icon mdi-chevron-right
</template>

<script>
export default {
  props: {
    ids: {
      type: Array,
      required: true,
    },
    ready: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    hasSpeech: !!window.SpeechSynthesisUtterance,
    hint: false,
    index: +localStorage.getItem(`cardIndex`) || 0,
    jumpTo: null,
    loaded: false,
    menu: false,
    revealed: false,
    sorted: true,
    term: {},
    terms: 0,
    trans: false,
    // jumpRules: [
    //   // val =>
    //   //   val === null ||
    //   //   (!Number.isNaN(+val) &&
    //   //     +val >= 1 &&
    //   //     +val <= this.terms &&
    //   //     Number.isInteger(+val)) ||
    //   //   `Invalid Item Nr.`,
    // ],
  }),
  computed: {
    jumpRules() {
      return [
        val =>
          val === null ||
          (!Number.isNaN(+val) &&
            +val >= 1 &&
            +val <= this.terms &&
            Number.isInteger(+val)) ||
          `Invalid Item Nr.`,
      ]
    },
    wordHint() {
      if (!this.term._id) return
      let words = this.term.de.split(` `)
      for (let word of words) {
        let hintWord = Array.from(word)
        let h = hintWord.shift()
        for (let letter of hintWord) {
          if (!/[A-Za-z]/.test(letter)) h += letter
          else h += `·`
        }
        words[words.indexOf(word)] = h
      }
      return words.join(` `)
    },
    cardSize() {
      let h, w
      switch (this.$vuetify.breakpoint.name) {
        case `md`:
          w = 475
          h = 235
          break
        case `xs`:
          w = 340
          h = 235
          break
        case `sm`:
          w = 400
          h = 235
          break
        default:
          w = 475
          h = 235
      }
      return { h, w }
    },
  },
  async beforeMount() {
    localStorage.setItem(`cardIndex`, this.index)
    const doc = await this.$bucket.db.get(this.ids[this.index])
    this.term = Object.assign({}, doc)
    this.terms = this.ids.length
    this.loaded = true
    this.trans = false
  },
  methods: {
    showHint() {
      this.hint = true
      umami(`Show hint`)
    },
    reveal() {
      umami(`Reveal word`)
      this.revealed = true
    },
    reset(nolog) {
      !nolog && umami(`Reset letter`)
      this.hint = false
      this.revealed = false
    },
    async next() {
      this.trans = true
      if (this.index + 1 === this.ids.length) this.index = 0
      else this.index++
      umami(`Next word`)
      await this.getTerm()
    },
    async previous() {
      if (!this.index) return
      this.trans = true
      this.index--
      umami(`Previous word`)
      await this.getTerm()
    },
    getTerm() {
      this.reset(1)
      Promise.all([
        this.$bucket.db.get(this.ids[this.index]).then(doc => {
          this.term = Object.assign({}, doc)
        }),
        new Promise(resolve => setTimeout(resolve, 300)),
      ]).then(
        () => (
          (this.trans = false),
          this.sorted && localStorage.setItem(`cardIndex`, this.index)
        )
      )
    },
    sayWord() {
      umami(`Pronounce word`)
      const utterance = new SpeechSynthesisUtterance(this.term.en)
      utterance.lang = `en-US`
      speechSynthesis.speak(utterance)
    },
    toggleSort(ev) {
      this.menu = ev
      this.$emit(`toggleSort`, ev)
      this.index = (ev && +localStorage.getItem(`cardIndex`)) || 0
      this.trans = true
      this.getTerm()
    },
    jumpToIndex() {
      if (!this.jumpTo) return
      this.trans = true
      this.index = +this.jumpTo - 1
      this.getTerm()
      this.jumpTo = null
      this.menu = false
    },
  },
}
</script>
